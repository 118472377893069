<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus">新增公告</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="noticeList" stripe style="width: 100%" height="100%">
        <el-table-column prop="title" label="标题" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="companyName" label="发布单位" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="releaseTime" label="发布时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.releaseTime|timeFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tooltip content="查看" placement="top" effect="dark">
              <el-button type="primary" circle icon="el-icon-view" size="small" @click="open('content',scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip content="修改" placement="top" effect="dark">
              <el-button type="warning" circle icon="el-icon-edit" size="small" @click="open('edit',scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip content="删除" placement="top" effect="dark">
              <el-button type="danger" circle icon="el-icon-delete" size="small" @click="delNotice(scope.row.id)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <el-dialog :title="optFlag?'发布公告':'修改公告'" :visible.sync="noticeShow" width="40%" top="3%" v-dialogDrag>
      <el-form :model="noticeForm" ref="noticeForm" :rules="rules" label-width="60px">
        <el-form-item label="标题" props="name">
          <el-input v-model="noticeForm.title" size="small" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <editor-bar v-model="noticeForm.content" :isClear="isClear" @change="changeContent"></editor-bar>
        </el-form-item>
      </el-form>
      <span>
        <el-button @click="noticeShow = false">取 消</el-button>
        <el-button type="primary" @click="addNotice" v-show="optFlag">发布公告</el-button>
        <el-button type="primary" @click="updateNotice" v-show="!optFlag">修改公告</el-button>
      </span>
    </el-dialog>
    <el-dialog title="公告内容" :visible.sync="contentShow" width="40%" top="3%">
      <div class="notice-content">
        <div class="content" v-html="noticeForm.content" v-show="noticeForm.content!==''">
        </div>
        <div class="content" v-show="noticeForm.content===''" style="text-align:center">
          暂无内容
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import EditorBar from '../../components/Editor'
export default {
  components: {
    EditorBar
  },
  data () {
    return {
      currentPage: 1,
      total: 0,
      optFlag: true,
      contentShow: false,
      noticeShow: false,
      noticeList: [],
      noticeId: 0,
      noticeForm: {
        title: '',
        content: ''
      },
      noticeFormCopy: {},
      searchForm: {},
      isClear: false,
      rules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTable()
    this.noticeFormCopy = JSON.stringify(this.noticeForm)
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 初始化表格
    async initTable () {
      const data = {}
      data.pageNo = this.currentPage
      data.pageSize = 10
      const { data: result } = await this.$axios.get('/notice/list', { params: data })
      if (result.code === 200) {
        this.noticeList = result.data.result
        this.total = result.data.total
      } else {
        this.$message.error(result.msg)
      }
    },
    async open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.noticeForm = {
            title: '',
            content: ''
          }
          this.noticeShow = true
          break
        case 'edit':
          this.optFlag = false
          this.noticeShow = true
          this.$nextTick(() => {
            this.$refs.noticeForm.clearValidate()
          })
          this.noticeForm = JSON.parse(JSON.stringify(row))
          this.noticeId = row.id
          break
        case 'content':
          this.contentShow = true
          this.noticeForm = JSON.parse(JSON.stringify(row))
          break
      }
    },
    // 添加公告
    addNotice () {
      this.$refs.noticeForm.validate(async valid => {
        if (valid) {
          if (this.searchForm.content === '') {
            this.$message.error('请输入公告内容')
          }
          const { data: result } = await this.$axios.post('/notice/add', this.noticeForm)
          if (result.code === 200) {
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.$message.success('添加成功')
            this.noticeShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除公告
    async delNotice (id) {
      const confirm = await this.$confirm('此操作将永久删除该公告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/notice/del/${id}`)
        if (result.code === 200) {
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.$message.success('删除成功')
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改公告
    async updateNotice () {
      this.$refs.noticeForm.validate(async valid => {
        if (valid) {
          this.noticeForm.id = this.noticeId
          const { data: result } = await this.$axios.put(`/notice/edit/${this.noticeId}`, this.noticeForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.noticeShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    changeContent (val) {

    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.el-dialog {
  .el-dialog__body {
    .el-form {
      ::v-deep .editor {
        .text {
          background-color: #fff;
        }
      }
    }
    .notice-content {
      padding: 50px 100px;
      width: 100%;
      border: 1px solid #e2e2e2;
      box-sizing: border-box;
      color: #fff;
    }
  }
}
</style>
